<template>
  <section
    class="product-card single-row-item j-expose__product-item"
    :class="{
      'hover-effect': config.useHoverEffect
    }"
    
    role="listitem"
    tabindex="0"
    :aria-label="goodsName"
    :data-expose-id="`${index}-${goodsInfo.goods_id}`"

    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
  >
    <div
      v-if="showSelect && selectPosition === 'mainLeft'"
      class="product-card__ctrl"
      @click="handleSelect"
    >
      <s-checkbox
        :model-value="selectStatus"
        :disabled="selectDisable"
        :class="['product-card__select', , checkedBoxStyle, checkedBoxShape]"
      />
    </div>
    <div 
      class="product-card__top-wrapper"
    >
      <!-- 主图 start -->
      <ProductCardImgs
        ref="imgContainer"
        :goods-info="goodsInfo"
        :pretreat-info="pretreatInfo"
        :locate-labels-ctrl="locateLabelsCtrl"
        :locate-labels="locateLabels"
        :show-select="showSelect"
        :select-position="selectPosition"
        :select-status="selectStatus"
        :select-disable="selectDisable"
        :show-similar-popup="showSimilarPopup"
        :language="language"
        :is-rw="IS_RW"
        :index="index"
        :forbid-jump-detail="forbidJumpDetail"
        :relate-color-info="relateColorInfo"
        :lazy-img="LAZY_IMG_SOLID_COLOR"
        :css-right="GB_cssRight"
        :open-target="openTarget"
        :show-spu-img="_showSpuImg"
        :goods-detail-url="goodsDetailUrl"
        :belt-price-ctrl="beltPriceCtrl"
        :product-design-width="productDesignWidth"
        :estimated-price-info="estimatedPriceInfo"
        :no-img-lazy="noImgLazy"
        :discount-info="discountInfo"
        :report-metrics="reportMetrics"
        @click-item="handleClickItem($event, { from: 'mainImg' })"
        @select="handleSelect"
      >
        <!-- 图片底部slot(嵌套) -->
        <template #imgContent>
          <!-- 售罄背景 -->
          <ProductCardImgSoldout 
            v-if="saleoutShow"
            :saleout="saleout"
          />
        </template>
        <!-- 图片底部slot(嵌套) -->
        <template #imgBottom>
          <slot name="imgBottom"></slot>
        </template>

        <!-- 图片底部slot(盖在腰带之上) -->
        <template #imgBottomInTop>
          <slot name="imgBottomInTop"></slot>
        </template>
      </ProductCardImgs>

      <ClientOnly>
        <!-- 加车以及找相似按钮 TODO: 是否必要-->
        <ProductCardOperation
          v-if="showOperation"
          ref="itemOperation"
          :sold-out="saleoutShow"
          :goods-info="goodsInfo"
          :config="config"
          :show-select="showSelect"
          :language="language"
          @addboard="emit('addboard', item)"
        >
          <template #addToBagBtn>
            <slot name="addToBagBtn"></slot>
          </template>
        </ProductCardOperation>

        <!-- 主图加载中 -->
        <s-product-item-loading 
          v-if="showLoading" 
          :show="showLoading" 
        />

        <!-- 主图左上区域 -->
        <div class="top-wrapper__top-left">
          <slot name="imgTopLeft"></slot>
        </div>
        <!-- client下 商品图片区域的slot -->
        <slot name="clientGoodsImg"></slot>
      </ClientOnly>

      <!-- 商品图片区域的slot -->
      <slot name="goodsImg"></slot>
    </div>
    <!-- 主图 end -->

    <div
      class="product-card__bottom-wrapper"
      :class="{ 'bottom-wrapper__saleout': saleoutShow }"
      :style="bottomInfoStyle"
      @click="handleClickBottomWrapper"
    >
      <slot name="cardTitleTop"></slot>

      <!-- 商品名称 -->
      <ProductItemTitleContainer
        ref="nameContainer"
        :goods-info="goodsInfo"
        :discount-info="discountInfo"
      />

      <!-- 卖点信息区域 -->
      <!-- 榜单、星级评论、用户行为标签等 -->
      <!-- 榜单卖点信息区域 或 赛马标签信息区域 -->
      <ProductCardHorseRaceLabelContainer
        v-if="config.showHorseRaceLabel"
        :goods-info="goodsInfo"
      />

      <ProductCardSellingPropositionContainer
        v-else
        :goods-info="goodsInfo"
      />

      <slot name="cardPriceTop"></slot>

      <div 
        v-if="showAddBagBtn || !config.hideAllBottomPrices"
        class="bottom-wrapper__price-wrapper"
      >
        <!-- 价格相关 -->
        <ProductCardPricesContainer 
          v-if="!config.hideAllBottomPrices"
          class="fsp-element"
          :is-rw="IS_RW"
          :css-right="GB_cssRight"
          :config="config"
          :pretreat-info="pretreatInfo"
          :index="index"
          :language="language"
          :sale-price="salePrice" 
          :retail-price="retailPrice"
          :show-select="showSelect"
          :goods-info="goodsInfo"
          :show-spu-img="_showSpuImg"
          :belt-label="beltPriceCtrl.beltLabel"
          :is-promo-price="beltPriceCtrl.isPromoPrice"
          :show-new-discount-label="config.showNewDiscountLabel"
          :discount-info="discountInfo"
          :item="item"
          :sold-out="saleoutShow"
          :estimated-info="estimatedInfo"
        />
      </div>

      <!-- 服务类标签 -->
      <ProductCardLabelsContainer
        :goods-info="goodsInfo"
      >
        <template #goodsServiceTag>
          <slot name="goodsServiceTag"></slot>
        </template>
      </ProductCardLabelsContainer>

      <div class="bottom-wrapper__footer">
        <div class="footer-content-left">
          <slot name="cardBottomAddCardLeft"></slot>
        </div>

        <!-- 加车按钮 -->
        <ProductCardAddBagBtn
          class="footer__addbag-btn"
          :sold-out="saleoutShow"
          @open-quick-add="handelOpenQuickAdd"
        />
      </div>

      <!-- 商铺跳转链接 -->
      <ProductCardStoreLink 
        v-if="showStoreBar" 
        :store-info="storeInfo" 
      />
      <!-- 商品底部slot -->
      <slot name="goodsBottom"></slot>
    </div>  

    <!-- 默认内容插槽 -->
    <slot name="goodsMain"></slot>
  </section>
</template>

<script name="SingleImageRowCard" setup>
import { defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

// components start
import { ProductCardImgs } from './components/ProductCardImgs'
import { ProductCardAddBagBtn } from './components/ProductCardAddBagBtn'
import ProductCardPricesContainer from './components/ProductCardPrice/ProductCardPricesContainer.vue'
import ProductItemTitleContainer from './components/ProductCardTitle/ProductItemTitleContainer.vue'
import ProductCardSellingPropositionContainer from './components/ProductCardSellingProposition/ProductCardSellingPropositionContainer.vue'
import ProductCardHorseRaceLabelContainer from './components/ProductCardSellingProposition/ProductCardHorseRaceLabelContainer.vue'
import ProductCardLabelsContainer from './components/ProductCardLabels/ProductCardLabelsContainer.vue'
import ProductCardImgSoldout from './components/ProductCardImgs/ProductCardImgSoldout.vue'

const ProductCardOperation = defineAsyncComponent(() => import('./components/ProductCardOperation'))
const SProductItemLoading = defineAsyncComponent(() => import('./components/ProductItemLoading.vue'))
const ProductCardStoreLink = defineAsyncComponent(() => import('./components/ProductCardStoreLink/ProductCardStoreLink.vue'))
// components end

import { cardProps, cardEmit, useCardMain } from './hooks/useCardMain.js'

// utils
import { views } from './utils/constant'

// 单列与多列取值key不同
const labelsFromKey = views.LIST_PC_BROADWISE_VIEW

const emit = defineEmits(cardEmit)

const props = defineProps(Object.assign(
  {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  }, 
  cardProps
))

// 单列选择居多，点击区域覆盖
const handleClickBottomWrapper = (e) => {
  const from = 'cardInfo' 

  const nameLink = nameContainer.value.nameLink
  const imgContainerTarget = imgContainer.value.$refs.imgContainer
  const target = nameLink || imgContainerTarget

  handleClickItem({ event: e, target }, { from })
}

const {
  // data
  relateColorInfo,
  goodsDetailUrl,

  // static config
  IS_RW,
  GB_cssRight, 
  LAZY_IMG_SOLID_COLOR, 

  // props
  item, 
  config, 
  language, 
  showSelect, 
  selectPosition,
  selectDisable,
  selectStatus, 
  index, 
  showSimilarPopup, 
  bottomInfoStyle, 
  openTarget, 
  productDesignWidth,
  noImgLazy,
  reportMetrics,

  // dom ref
  imgContainer,
  itemOperation,
  nameContainer,

  pretreatInfo,
  goodsInfo,
  discountInfo,

  locateLabels,
  locateLabelsCtrl,

  _showSpuImg,

  saleout,
  saleoutShow,
  beltPriceCtrl,
  goodsName,

  showLoading,

  forbidJumpDetail,
  estimatedPriceInfo,
  salePrice,
  retailPrice,
  estimatedInfo,

  storeInfo,
  showStoreBar,

  showOperation,
  showAddBagBtn,
  handelOpenQuickAdd,

  handleClickItem,
  handleSelect,

  handleMouseenter,
  handleMouseleave,

  setElAttr,
  setAttrForAnalysis
} = useCardMain({
  emit,
  props,
  labelsFromKey
})

const checkedBoxStyle = config?.style?.checkedBoxStyle || ''
const checkedBoxShape = config?.style?.checkedBoxShape || ''

// 外部ref调用
defineExpose({
  setElAttr,
  setAttrForAnalysis
})

</script>


<style lang="less">
@import url(./style/checkBox.less);
@import url(./style/icon.less);

.single-row-item {
  display: flex;
  font-size: 0;

  background-color: var(--product-card-bg);
  background-image: var(--product-card-bg-image);
  .product-card__ctrl {
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    overflow: hidden; // 隐藏checkbox内部的margin问题
  }

  .product-card__top-wrapper {
    position: relative;

    margin-left: 12px;
    margin-right: 12px;

    flex: 0 0 120px;
    flex-basis: var(--product-card-top-wrapper-flex-basis, 120px);

    border-radius: var(--product-card-top-wrapper-border-radius, 0);
    overflow: hidden;
    .top-wrapper {
      // 主图左上区域
      &__top-left {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
      }
    }
  }

  .product-card__bottom-wrapper {
    flex: 1 1 auto;
    min-height: 74px;
    min-width: 0;

    display: flex;
    flex-direction: column;

    .bottom-wrapper__price-wrapper {
      margin-top: 4px;
      
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .bottom-wrapper__saleout {
    & > div, & > section {
      opacity: 0.3;
    }

    // 色块选择不受影响
    & > .product-card__relatecolor-container {
      opacity: 1;
    }
  }

  .bottom-wrapper__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1 1 auto;

    .footer__addbag-btn {
      flex: 0 0 auto;
    }
  }
}
.product-card.hover-effect:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
}
</style>
